<template>

  <div class="row" ref="userForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-6 col-md-6 col-sm-12">

                <ValidationProvider
                  vid="first_name"
                  rules="required"
                  name="First Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="First Name"
                            name="first_name"
                            fou
                            v-model="formData.first_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="last_name"
                  rules="required"
                  name="Last Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Last Name"
                            name="last_name"
                            fou
                            v-model="formData.last_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="email"
                  rules="required|email"
                  name="Email"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="email"
                            :error="failed ? errors[0]: null"
                            label="Email"
                            name="email"
                            fou
                            v-model="formData.email">
                  </fg-input>
                </ValidationProvider>


                <fg-input type="mobile"
                          label="Mobile"
                          name="mobile"
                          fou
                          v-model="formData.mobile">
                </fg-input>

                <ValidationProvider
                  vid="role_id"
                  rules="required"
                  name="The Role"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="role_id"
                    size="large"
                    filterable
                    placeholder="Select Role"
                    :input-classes="'select-default'"
                    :label="'Roles'"
                    :error="failed ? errors[0]: null"
                    :list="roles"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.role_id">
                  </fg-select>
                </ValidationProvider>

                <ValidationProvider
                  v-if="!editMode"
                  vid="password"
                  rules="required"
                  name="The Password"
                  v-slot="{ passed, failed,errors }"
                >
                  <fg-input type="password"
                            :error="failed ? errors[0]: null"
                            :hasSuccess="passed"
                            label="Password"
                            v-model="formData.password">
                  </fg-input>
                </ValidationProvider>

                <div class="form-group">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
              <div class="col-xs-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Profile Image:</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>

                  <image-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.profile_image"></image-uploader>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/users/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Switch as LSwitch} from '@/components'
import {ImageUploader} from '@/components'
import {FormGroupSelect} from '@/components'
import FgSelect from "@/components/Inputs/formGroupSelect";
import {mapGetters} from "vuex";
import { Tooltip} from 'element-ui';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FgSelect,
    [Tooltip.name]: Tooltip,
    LSwitch,
    FormGroupSelect,
    ImageUploader
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })

  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        profile_image: "",
        is_active: true,
        role_id: null,
        password: ""
      },

      roles: []

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.userForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit User";
    } else {
      this.editMode = false;
      this.formTitle = "Add User";
    }

    this.axios.get("users/builder").then((response) => {
      this.roles = response.data.roles;
      if (this.editMode) {
        this.getUser();
      } else {
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getUser() {
      this.axios.get("users/get/" + this.id).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "User Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        let updateData = {...this.formData};
        this.$delete(updateData, 'password');
        request = this.axios.put("users/update/" + this.id, updateData);
        successMessage = "User Updated Successfully";
      } else {
        request = this.axios.post("users/create", this.formData);
        successMessage = "User Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/users/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    },
    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo['banner_image_users']) {
        return this.mediaInfo["banner_image_users"];
      } else {
        return "";
      }
    }

  }
}
</script>

<style>
</style>
